"use client";

import { useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { CookieIcon } from "../icons/cookie-icon";

export default function CookieConsent() {
  const [consent, setConsent] = useState<null | boolean>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);

  const userUnderstands = () => {
    setIsOpen(false);
    document.cookie =
      "cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    setTimeout(() => {
      setHide(true);
    }, 700);
    onUserUnderstandsCallback();
  };

  const onUserUnderstandsCallback = () => {
    setConsent(true);
    localStorage.setItem("user-consent", "true");
    // TODO GTM event consent
  };

  useEffect(() => {
    const savedConsent = localStorage.getItem("user-consent");
    if (savedConsent !== null) {
      setConsent(JSON.parse(savedConsent));
    }

    try {
      if (!document.cookie.includes("cookieConsent=true")) {
        setIsOpen(true);
      } else {
        setTimeout(() => {
          setHide(true);
        }, 700);
      }
    } catch (e) {
      // console.log("Error: ", e);
    }
  }, []);

  if (consent) return null;

  return (
    <div
      className={cn(
        "fixed z-[200] bottom-0 left-0 right-0 sm:left-4 sm:bottom-4 w-full sm:max-w-md duration-700",
        !isOpen
          ? "transition-[opacity,transform] translate-y-8 opacity-0"
          : "transition-[opacity,transform] translate-y-0 opacity-100",
        hide && "hidden"
      )}
    >
      <div className="bg-card rounded-md m-3 border border-border shadow-lg dark:shadow-none">
        <div className="grid gap-2">
          <div className="border-b border-border dark:border-background/20 h-14 flex items-center justify-between p-4">
            <h1 className="text-lg font-medium">
              Nous avons besoin de cookies 😋
            </h1>
            <CookieIcon className="size-6" />
          </div>
          <div className="p-4 text-sm font-normal text-start">
            <p className="mb-2">
              Nous utilisons des cookies pour vous garantir une expérience
              optimale sur notre site web et son bon fonctionnement.
              <br />
            </p>

            <ul className="mb-4">
              <li>
                <p>
                  - <b>Google Analytics</b> pour comprendre comment notre site
                  web est utilisé.
                </p>
              </li>
              <li>
                <p>
                  - <b>Hotjar</b> pour voir si vous êtes heureux ou frustré.
                </p>
              </li>
              <li>
                <p>
                  - <b>Crisp</b> pour le support client.
                </p>
              </li>
            </ul>

            <p className="mb-4">
              C&apos;est tout! Visitez notre{" "}
              <a
                href="/privacy"
                className="text-primary dark:text-white underline"
              >
                politique de confidentialité
              </a>{" "}
              pour plus d&apos;informations.
              <br />
            </p>
            <p className="text-md">
              En continuant à naviguer, vous acceptez notre utilisation des
              cookies.
            </p>
          </div>
          <div className="flex gap-2 p-4 py-5 border-t border-border dark:bg-background/20">
            <Button onClick={userUnderstands} className="w-full">
              Je comprends
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
