"use client";

import Script from "next/script";

const GoogleTagManagerHead = ({ gtmId }: { gtmId: string }) => {
  const isProduction = process.env.NODE_ENV === "production";

  if (!isProduction) {
    return null;
  }

  return (
    <Script id="gtm-head" strategy="afterInteractive">
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `}
    </Script>
  );
};

export default GoogleTagManagerHead;
