import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientSideConsentHead","ClientSideConsentBody"] */ "/home/vsts/work/1/s/components/integrations/ClientCookieConsent.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/components/integrations/CrispChat.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/components/integrations/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/components/integrations/GoogleTagManagerBody.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/components/integrations/GoogleTagManagerHead.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/components/integrations/Hotjar.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/components/integrations/WebsiteCarbonBadge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/vsts/work/1/s/components/layout/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/vsts/work/1/s/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/home/vsts/work/1/s/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
