import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <style>
        {`
          .cls-1 { fill: #fff; }
          .cls-2 { fill: #6d3c17; }
          .cls-3 { fill: #c27c27; }
          .cls-4 { fill: #d69241; }
          .cls-5 { fill: #c88632; }
          .cls-6 { fill: #54320f; }
        `}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          className="cls-1"
          d="m22.66,16.23s.03-.03.02-.06c.15-.28.27-.58.34-.89.02-.03.04-.05.06-.09.36-.91.7-1.82.84-2.79.04-.26.1-.52.08-.77-.05-.51-.21-.99-.41-1.45-.26-.59-.54-1.16-.8-1.74-.2-.45-.45-.88-.57-1.36-.13-.49-.22-.99-.37-1.47-.31-1.01-.99-1.69-1.8-2.25-.42-.29-.87-.55-1.31-.8-.59-.35-1.19-.69-1.79-1.02-.21-.12-.44-.2-.64-.36-.63-.51-1.33-.87-2.13-1-.21-.04-.43-.08-.64-.09-.45-.03-.9-.05-1.35-.05-.2,0-.41-.06-.61-.03-.36.06-.73.04-1.1.08-.08,0-.14-.09-.21-.03-.15.11-.32.09-.48.11-.32.04-.63.12-.94.21-.41.11-.8.3-1.16.53-.24.16-.51.3-.69.55-.19-.06-.39-.04-.58-.04-.93,0-1.61.67-1.64,1.63-.03.02-.06.03-.09.05-.56.48-1.17.92-1.65,1.5-.27.33-.53.68-.69,1.09-.23.59-.39,1.21-.64,1.8-.33.76-.65,1.53-1,2.28-.26.57-.51,1.14-.64,1.76-.04.18-.08.36-.03.56.26.96.68,1.84,1.09,2.73-.37.52-.33,1.05-.05,1.58.19.36.51.56.85.73.1.43.31.81.49,1.21.43.94,1.11,1.64,1.93,2.2.87.59,1.68,1.27,2.47,1.96.5.44,1.07.74,1.69.95.51.17,1.04.28,1.56.42.29.08.59.18.9.13.51-.09,1.02-.12,1.54-.12.6,0,1.2.01,1.8-.08.6-.09,1.18-.24,1.72-.52.69-.36,1.26-.89,1.83-1.41.63-.58,1.28-1.12,1.94-1.65.53-.42,1.07-.84,1.51-1.38.62-.77,1.03-1.65,1.36-2.59h0Z"
        />
        <path
          className="cls-3"
          d="m7,1.46c.18-.25.44-.39.69-.55.36-.23.74-.42,1.16-.53.31-.08.62-.17.94-.21.16-.02.34,0,.48-.11.07-.06.14.04.21.03.36-.04.73-.03,1.1-.08.2-.03.41.03.61.03.45,0,.9.02,1.35.05.21.02.43.06.64.09.79.14,1.5.49,2.13,1,.2.16.43.24.64.36.6.33,1.19.67,1.79,1.02.44.26.89.51,1.31.8.81.56,1.49,1.24,1.8,2.25.15.49.25.98.37,1.47.12.48.37.91.57,1.36.26.58.54,1.16.8,1.74.2.46.36.95.41,1.45.03.25-.04.52-.08.77-.14.97-.48,1.89-.84,2.79-.01.03-.04.06-.06.09.05-.77-.14-1.44-.75-1.92.06-.25.14-.49.19-.74.04-.22.1-.44,0-.67-.19-.49-.31-1-.45-1.51-.18-.66-.41-1.31-.61-1.97-.31-1.04-.63-2.07-1.33-2.91-.18-.21-.34-.47-.64-.54-.03-1.29-1.09-2.26-2.4-1.98,0-.09-.06-.15-.13-.2-.26-.2-.52-.39-.77-.6-.99-.83-2.16-1.09-3.38-1.17-.81-.05-1.63.04-2.44.09-.36.02-.73.07-1.09.15-.5.11-.95.34-1.42.53-.26-.17-.5-.36-.82-.4Z"
        />
        <path
          className="cls-3"
          d="m22.66,16.24c-.33.93-.74,1.81-1.36,2.59-.44.54-.98.96-1.51,1.38-.66.53-1.32,1.07-1.94,1.65-.57.52-1.14,1.05-1.83,1.41-.54.28-1.12.43-1.72.52-.6.09-1.2.08-1.8.08-.52,0-1.03.04-1.54.12-.31.05-.6-.05-.9-.13-.52-.14-1.05-.24-1.56-.42-.62-.21-1.19-.51-1.69-.95-.79-.69-1.6-1.37-2.47-1.96-.82-.55-1.5-1.26-1.93-2.2-.18-.4-.4-.78-.49-1.21.14.03.28.06.42.09,0,.03,0,.06,0,.08.43.89,1.04,1.61,1.85,2.14.59.39,1.16.8,1.63,1.34.06.07.11.15.21.16.02.17.08.33.14.49.46,1.19,1.97,1.76,2.94.8.32.09.64.14.96.19.61.1,1.23.18,1.85.19.56,0,1.13.07,1.7.03.82-.06,1.57-.28,2.25-.76,1.08-.75,2.1-1.58,3.18-2.34.82-.57,1.41-1.36,1.76-2.33.06-.16.15-.31.15-.49.05,0,.1,0,.14.03.55.19,1,0,1.4-.39.05-.05.08-.12.16-.13Z"
        />
        <path
          className="cls-3"
          d="m1.12,14.82c-.41-.89-.84-1.77-1.09-2.73-.05-.2,0-.38.03-.56.13-.62.38-1.19.64-1.76.34-.76.67-1.52,1-2.28.25-.59.41-1.21.64-1.8.16-.41.42-.76.69-1.09.48-.58,1.09-1.02,1.65-1.5.03-.02.06-.03.09-.05,0,.27.12.51.24.73-.34.18-.62.44-.9.69-.54.48-1.01,1.02-1.24,1.75-.12.37-.14.75-.25,1.12-.41,1.35-.98,2.64-1.57,3.91-.22.46-.38.95-.39,1.47-.02.72.25,1.38.54,2.02-.03.03-.05.05-.08.08Z"
        />
        <path
          className="cls-6"
          d="m22.66,16.24c-.08,0-.11.08-.16.13-.4.39-.85.58-1.4.39-.04-.02-.09-.02-.14-.03-.23-.14-.42-.32-.62-.5-.28-.49-.43-1-.34-1.6.11-.74.72-1.54,1.57-1.5.24.04.48.11.7.24.61.49.8,1.15.75,1.92-.07.31-.18.61-.34.89-.02.01-.03.03-.02.06h0Zm-2.32-1.05c-.02.28.08.53.24.77.33.49,1.04.53,1.4.08.38-.48.41-1.2.02-1.68-.28-.35-.73-.52-1.16-.21-.33.24-.52.58-.51,1.04Z"
        />
        <path
          className="cls-6"
          d="m5.01,3.78c-.13-.23-.24-.46-.24-.73.04-.96.72-1.63,1.64-1.63.19,0,.39-.02.58.04.32.03.56.22.82.4.18.19.32.4.43.64,0,.04,0,.07,0,.11.15.55.03,1.02-.37,1.41-.48.47-1.05.66-1.7.54-.14-.03-.27-.08-.41-.13-.29-.17-.56-.35-.75-.64Zm1.32,0c.27.02.52-.07.73-.23.45-.33.55-.87.25-1.3-.3-.43-.92-.62-1.4-.42-.58.24-.86.8-.62,1.31.21.44.58.62,1.03.64Z"
        />
        <path
          className="cls-6"
          d="m1.12,14.82s.05-.05.08-.08c.28-.22.58-.33.95-.32.29,0,.52.13.78.23.59.41.84.98.73,1.71-.05.11-.09.22-.15.32-.27.43-.69.56-1.16.55-.14-.03-.28-.06-.42-.09-.35-.17-.66-.38-.85-.73-.29-.53-.32-1.07.05-1.58Zm1.01-.14c-.52-.02-.85.41-.8.83.05.39.25.67.59.84.53.28,1.15,0,1.21-.53.06-.61-.41-1.15-1-1.15Z"
        />
        <path
          className="cls-3"
          d="m22.66,16.23s0-.05.02-.06c0,.03,0,.05-.02.06Z"
        />
        <path
          className="cls-5"
          d="m8.24,2.49c-.11-.24-.25-.45-.43-.64.47-.19.92-.43,1.42-.53.36-.08.73-.13,1.09-.15.81-.05,1.62-.14,2.44-.09,1.22.07,2.39.34,3.38,1.17.25.21.51.4.77.6.07.05.13.11.13.2-.37.08-.59.37-.82.65-.07,0-.11-.05-.15-.09-.38-.33-.76-.65-1.2-.89-.7-.38-1.47-.47-2.24-.52-.37-.02-.74.03-1.1-.01-.38-.05-.76-.07-1.14-.03-.45.05-.91.06-1.36.15-.27.05-.52.18-.79.19Z"
        />
        <path
          className="cls-5"
          d="m22.27,13.36c-.22-.13-.45-.19-.7-.24.1-.42.11-.86.26-1.27.04-.1.03-.19.01-.29-.09-.48-.24-.94-.45-1.37-.56-1.11-.99-2.29-1.59-3.37-.19-.35-.36-.72-.71-.95.21-.24.26-.55.35-.85.3.07.46.33.64.54.7.84,1.03,1.87,1.33,2.91.2.66.43,1.31.61,1.97.14.51.26,1.02.45,1.51.09.22.03.45,0,.67-.05.25-.12.5-.19.74Z"
        />
        <path
          className="cls-6"
          d="m19.44,5.03c-.08.3-.14.61-.35.85-.65.57-1.33.64-2.07.18-.84-.52-1.23-1.47-.8-2.37.23-.28.45-.57.82-.65,1.31-.28,2.37.69,2.4,1.98Zm-2.81-.79c0,.11,0,.23.04.35.2.52.58.81,1.1.86.63.06,1.21-.5.93-1.3-.17-.48-.52-.74-.97-.83-.67-.13-1.15.4-1.1.92Z"
        />
        <path
          className="cls-5"
          d="m20.35,16.22c.2.18.39.36.62.5,0,.18-.09.33-.15.48-.35.97-.94,1.75-1.76,2.33-1.08.76-2.1,1.58-3.18,2.34-.68.47-1.43.69-2.25.76-.57.04-1.13-.02-1.7-.03-.62,0-1.23-.09-1.85-.19-.32-.05-.65-.1-.96-.19.17-.24.28-.51.31-.81.3.06.6.14.9.19.26.04.5.18.78.16.17-.02.34-.05.51-.08.37-.05.74-.09,1.1-.13.97-.1,1.88-.42,2.74-.87.87-.45,1.67-1.02,2.47-1.6.97-.7,1.77-1.56,2.28-2.69.03-.07.05-.15.13-.18Z"
        />
        <path
          className="cls-5"
          d="m2.34,17.22c.47,0,.88-.12,1.16-.55.06-.1.1-.21.15-.32.1,0,.1.09.12.15.28.74.69,1.39,1.27,1.91.5.44,1.03.84,1.55,1.25-.37.34-.58.75-.55,1.28-.1,0-.15-.1-.21-.16-.47-.54-1.04-.96-1.63-1.34-.81-.52-1.43-1.25-1.85-2.14-.01-.02,0-.06,0-.08Z"
        />
        <path
          className="cls-6"
          d="m6.04,20.94c-.04-.53.18-.94.55-1.28.72-.47,1.42-.37,2.08.11.54.39.81.95.77,1.65-.03.3-.14.57-.31.81-.97.96-2.48.39-2.94-.8-.06-.16-.12-.32-.14-.49Zm1.52-1.24c-.51-.08-1.02.46-.93,1.03.12.75.88,1.29,1.62,1.02.32-.12.58-.55.56-.87-.06-.67-.59-1.17-1.24-1.17Z"
        />
        <path
          className="cls-5"
          d="m2.92,14.64c-.25-.1-.49-.22-.78-.23-.36,0-.67.1-.95.32-.29-.64-.55-1.29-.54-2.02.01-.52.17-1.01.39-1.47.6-1.27,1.16-2.56,1.57-3.91.11-.36.13-.75.25-1.12.23-.73.7-1.27,1.24-1.75.28-.25.56-.52.9-.69.19.29.47.47.75.64,0,.06-.03.11-.08.15-.2.18-.41.36-.61.54-.66.57-1.18,1.25-1.37,2.14-.07.34-.09.7-.21,1.02-.24.63-.5,1.25-.79,1.85-.23.48-.44.98-.54,1.52-.03.13-.08.27-.03.42.06.2.12.39.17.59.13.45.17.93.37,1.37.09.21.2.4.25.63Z"
        />
        <path
          className="cls-4"
          d="m2.92,14.64c-.05-.23-.16-.42-.25-.63-.2-.44-.23-.92-.37-1.37-.06-.2-.11-.4-.17-.59-.05-.15,0-.28.03-.42.11-.53.31-1.03.54-1.52.29-.61.55-1.23.79-1.85.12-.32.14-.68.21-1.02.2-.9.71-1.57,1.37-2.14.2-.18.41-.36.61-.54.04-.04.08-.08.08-.15.14.04.27.1.41.13.65.12,1.22-.06,1.7-.54.39-.39.52-.86.37-1.41,0-.03,0-.07,0-.11.28-.01.53-.14.79-.19.45-.09.91-.1,1.36-.15.38-.04.76-.02,1.14.03.36.05.74,0,1.1.01.77.04,1.54.14,2.24.52.44.24.82.56,1.2.89.05.04.08.1.15.09-.43.89-.04,1.85.8,2.37.74.45,1.42.39,2.07-.18.35.22.51.6.71.95.61,1.09,1.03,2.26,1.59,3.37.21.43.36.89.45,1.37.02.1.02.2-.01.29-.15.41-.17.85-.26,1.27-.85-.04-1.46.76-1.57,1.5-.09.59.06,1.1.34,1.6-.08.03-.1.11-.13.18-.51,1.12-1.31,1.99-2.28,2.69-.8.58-1.6,1.14-2.47,1.6-.86.45-1.77.77-2.74.87-.37.04-.74.08-1.1.13-.17.02-.34.06-.51.08-.28.03-.52-.12-.78-.16-.3-.05-.6-.13-.9-.19.04-.7-.23-1.26-.77-1.65-.66-.48-1.36-.58-2.08-.11-.52-.42-1.05-.82-1.55-1.25-.58-.51-.99-1.17-1.27-1.91-.02-.06-.02-.15-.12-.15.1-.73-.15-1.3-.73-1.71Zm2.39-4.08c0,.31.09.58.22.85.29.61.93.81,1.49.69.38-.08.71-.28.97-.59.49-.58.66-1.25.42-2-.24-.75-1.09-1.11-1.8-.84-.82.31-1.29,1.25-1.3,1.88Zm9.42,6.15c.74-.04,1.3-.35,1.63-1.05.16-.35.16-.72.04-1.08-.17-.48-.61-.75-1.12-.76-.69-.02-1.21.27-1.57.88-.25.42-.29.86-.07,1.31.23.47.63.65,1.1.71Zm-1.04,3.08c.28.02.54-.13.77-.38.15-.17.22-.38.2-.61-.06-.74-.95-1.43-1.72-1.03-.31.16-.57.53-.55.84.04.7.66,1.15,1.3,1.18Zm-.19-16.23c-.12-.02-.29.04-.46.1-.53.2-.86.9-.68,1.39.18.48.65.72,1.22.56.67-.19,1.04-.65.94-1.37,0-.04,0-.07-.03-.11-.2-.4-.51-.57-.99-.58Z"
        />
        <path
          className="cls-2"
          d="m20.34,15.18c0-.46.18-.8.51-1.04.42-.31.88-.13,1.16.21.39.48.36,1.2-.02,1.68-.37.46-1.08.42-1.4-.08-.16-.24-.26-.5-.24-.77Z"
        />
        <path
          className="cls-2"
          d="m6.33,3.78c-.45-.02-.82-.2-1.03-.64-.24-.5.04-1.06.62-1.31.48-.2,1.09-.02,1.4.42.3.43.19.97-.25,1.3-.22.16-.46.25-.73.23Z"
        />
        <path
          className="cls-2"
          d="m2.13,14.68c.59,0,1.06.54,1,1.15-.05.52-.68.81-1.21.53-.34-.18-.54-.46-.59-.84-.05-.43.27-.85.8-.83Z"
        />
        <path
          className="cls-2"
          d="m16.64,4.24c-.05-.52.44-1.05,1.1-.92.45.09.8.35.97.83.28.81-.29,1.36-.93,1.3-.52-.05-.9-.34-1.1-.86-.04-.12-.05-.24-.04-.35Z"
        />
        <path
          className="cls-2"
          d="m7.56,19.7c.65,0,1.19.5,1.24,1.17.03.33-.24.75-.56.87-.73.28-1.5-.27-1.62-1.02-.09-.57.42-1.11.93-1.03Z"
        />
        <path
          className="cls-6"
          d="m5.31,10.57c.01-.63.48-1.57,1.3-1.88.72-.27,1.56.08,1.8.84.24.74.07,1.41-.42,2-.26.31-.59.51-.97.59-.57.12-1.21-.09-1.49-.69-.13-.27-.22-.54-.22-.85Zm2.35-.26c.05-.73-.66-1.26-1.34-.87-.46.27-.71.68-.68,1.24.04.63.51.96.97.9.63-.08,1.08-.68,1.05-1.27Z"
        />
        <path
          className="cls-6"
          d="m14.73,16.71c-.47-.05-.87-.23-1.1-.71-.22-.45-.18-.89.07-1.31.36-.61.88-.9,1.57-.88.51.02.95.28,1.12.76.13.36.12.73-.04,1.08-.33.7-.89,1.01-1.63,1.05Zm.22-2.46c-.42.01-.73.19-.96.51-.39.53-.13,1.22.49,1.34.42.08.84-.12,1.1-.56.17-.28.2-.57.04-.87-.15-.3-.42-.39-.68-.43Z"
        />
        <path
          className="cls-6"
          d="m13.69,19.79c-.63-.03-1.25-.49-1.3-1.18-.02-.3.24-.67.55-.84.77-.4,1.66.29,1.72,1.03.02.23-.05.44-.2.61-.22.25-.49.4-.77.38Zm-.93-1.31c0,.38.39.75.79.75.33,0,.66-.3.65-.59,0-.41-.41-.79-.81-.79-.35,0-.63.29-.63.62Z"
        />
        <path
          className="cls-6"
          d="m13.5,3.56c.47,0,.79.18.99.58.02.04.02.07.03.11.11.72-.26,1.18-.94,1.37-.56.16-1.04-.08-1.22-.56-.18-.5.15-1.19.68-1.39.16-.06.33-.13.46-.1Zm-.34,1.68c.42,0,.8-.35.8-.74,0-.35-.27-.64-.6-.62-.48.03-.81.37-.8.77,0,.33.27.59.6.59Z"
        />
        <path
          className="cls-2"
          d="m7.66,10.31c.03.6-.42,1.19-1.05,1.27-.45.06-.93-.26-.97-.9-.03-.56.22-.98.68-1.24.68-.39,1.39.14,1.34.87Z"
        />
        <path
          className="cls-2"
          d="m14.95,14.25c.26.04.52.13.68.43.16.3.12.59-.04.87-.26.43-.68.64-1.1.56-.62-.13-.88-.82-.49-1.34.23-.32.54-.5.96-.51Z"
        />
        <path
          className="cls-2"
          d="m12.76,18.48c0-.33.29-.62.63-.62.4,0,.8.38.81.79,0,.28-.33.58-.65.59-.39,0-.79-.37-.79-.75Z"
        />
        <path
          className="cls-2"
          d="m13.16,5.24c-.33,0-.59-.26-.6-.59,0-.4.32-.74.8-.77.33-.02.6.27.6.62,0,.39-.38.74-.8.74Z"
        />
      </g>
    </g>
  </svg>
);
export { SvgComponent as CookieIcon };
