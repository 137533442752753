"use client";

import { importIntegrationsNoSSRWithConsent } from "@/lib/integrations";
import CookieConsent from "@/components/integrations/CookieConsent";

export const ClientSideConsentHead = () => {
  const { GoogleAnalytics, GoogleTagManagerHead } =
    importIntegrationsNoSSRWithConsent();

  return (
    <>
      <GoogleTagManagerHead gtmId="GTM-THCBMQSG" />
      <GoogleAnalytics gaId="G-9HR8GP88BD" />
    </>
  );
};

export const ClientSideConsentBody = () => {
  const { Hotjar, GoogleTagManagerBody } = importIntegrationsNoSSRWithConsent();

  return (
    <>
      <GoogleTagManagerBody gtmId="GTM-THCBMQSG" />
      <Hotjar hotjarId={3870874} />
      <CookieConsent />
    </>
  );
};
