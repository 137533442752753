import dynamic from "next/dynamic";

export const importIntegrationsNoSSR = () => {
  return {
    CrispChat: dynamic(() => import("../components/integrations/CrispChat"), {
      ssr: false,
    }),
    WebsiteCarbonBadge: dynamic(
      () => import("../components/integrations/WebsiteCarbonBadge"),
      {
        ssr: false,
      }
    ),
  };
};

export const importIntegrationsNoSSRWithConsent = () => {
  return {
    Hotjar: dynamic(() => import("../components/integrations/Hotjar"), {
      ssr: false,
    }),
    GoogleAnalytics: dynamic(
      () => import("../components/integrations/GoogleAnalytics"),
      {
        ssr: false,
      }
    ),
    GoogleTagManagerHead: dynamic(
      () => import("../components/integrations/GoogleTagManagerHead"),
      {
        ssr: false,
      }
    ),
    GoogleTagManagerBody: dynamic(
      () => import("../components/integrations/GoogleTagManagerBody"),
      {
        ssr: false,
      }
    ),
  };
};
