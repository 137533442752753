"use client";

const GoogleTagManagerBody = ({ gtmId }: { gtmId: string }) => {
  const isProduction = process.env.NODE_ENV === "production";

  if (!isProduction) {
    return null;
  }

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
  );
};

export default GoogleTagManagerBody;
