"use client";

import Script from "next/script";

const WebsiteCarbonBadge = ({ darkMode }: { darkMode: boolean }) => {
  return (
    <>
      <div
        id="wcb"
        className={`wcb carbonbadge${darkMode ? ` wcb-d` : ""}`}
      ></div>
      <Script
        src="https://unpkg.com/website-carbon-badges@1.1.3/b.min.js"
        strategy="afterInteractive"
        defer
      />
    </>
  );
};

export default WebsiteCarbonBadge;
